import React, { Component } from "react";
import "./Contact.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      loading: false,
    };
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnChangeInput(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleOnSubmit = function(event) {
    event.preventDefault();

    const body = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };
    this.setState({ loading: true });

    fetch("http://localhost:5000/tradicion-alimentos/us-central1/httpEmail", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        this.setState({
          name: "",
          email: "",
          message: "",
          loading: false,
        });
        console.log("Success:", response);
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <div className={"modal-wrapper " + (!this.props.show && "hidden")}>
        <div className="modal-overlay" />
        <div className={"modal " + (!this.props.show && "hidden")}>
          <div className="left-side-wrapper">
            <div className="left-side">
              <h1 className="title">Comunicate con nosotros</h1>
              <h2 className="subtitle">
                Tu sugerencia, duda o consulta es muy importante para nosotros.
              </h2>

              <div className="contact-item">
                <div className="contact-item-icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="contact-item-line1">+54 358 575-7678</div>
                <div className="contact-item-line2">escribinos por WA</div>
              </div>
              <div className="contact-item">
                <div className="contact-item-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className="contact-item-line1">
                  info@tradicionalimentos.com
                </div>
              </div>
              <div className="contact-item">
                <div className="contact-item-icon">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </div>
                <div className="contact-item-line1">
                  Presidente Perón Centro 360
                </div>
                <div className="contact-item-line2">CP 5800</div>
                <div className="contact-item-line3">Río Cuarto (Córdoba)</div>
              </div>
              <button
                className="btn-close-modal"
                onClick={() => this.props.setShowContact(false)}
              >
                cerrar
              </button>
            </div>
          </div>
          {/*
          <div className="right-side">
            <form className="contact-form" onSubmit={this.handleOnSubmit}>
              <div className="form-field">
                <input className="contact-form-name" name="name" type="text" required placeholder="Nombre" value={this.state.name} onChange={this.handleOnChangeInput} />
              </div>
              <div className="form-field">
                <input className="contact-form-email" name="email" type="email" required placeholder="Email" value={this.state.email} onChange={this.handleOnChangeInput} />
              </div>
              <div className="form-field">
                <textarea name="message" type="text" required placeholder="Mensaje" value={this.state.message} onChange={this.handleOnChangeInput} />
              </div>          
              <button>{this.state.loading ? 'Enviando' : 'Enviar Mensaje'}</button>
            </form>
          </div>
          */}
        </div>
      </div>
    );
  }
}
