import React, { useState, useEffect } from "react";
import { Element, animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "./App.scss";
import Header from "./components/Header/Header";
import Product from "./components/Product/Product";
import AboutUs from "./components/AboutUs/AboutUs";
import Footer from "./components/Footer/Footer";
import alfajoresNegrosPic from "./alfajores_negros_tradicion.png";
import alfajoresCordobesesMixtosPic from "./alfajores_cordobeses_mixtos_tradicion.png";
import alfajoresCordobesesDulceDeLechePic from "./alfajores_cordobeses_dulce_de_leche_tradicion.png";
import alfajoresMaicenaPic from "./alfajores_cordobeses_maicena_tradicion.png";

export default function App() {
  const [products] = useState({
    alfajoresNegros: {
      id: "s1",
      titleLine1: "ALFAJORES NEGROS",
      titleLine2: "DULCE DE LECHE",
      picture: alfajoresNegrosPic,
      color: "#fdeccb",

      detailLine1:
        "Alfajor negro relleno con dulce de leche cubierto con un baño de chocolate semiamargo.",
      detailLine2: "Presentación: caja por 12 y por 6 unidades.",
    },
    alfajoresCordobesesDulceDeLeche: {
      id: "s2",
      titleLine1: "ALFAJOR CORDOBESES",
      titleLine2: "DE DULCE DE LECHE",
      picture: alfajoresCordobesesDulceDeLechePic,
      color: "#bbddd4",

      detailLine1:
        "Alfajor cordobés relleno con dulce de leche cubierto con un fino glaseado.",
      detailLine2: "Presentación: caja por 12 unidades.",
    },
    alfajoresCordobesesMixtos: {
      id: "s3",
      titleLine1: "ALFAJORES CORDOBESES",
      titleLine2: "MIXTOS",
      picture: alfajoresCordobesesMixtosPic,
      color: "#fdeccb",
      detailLine1:
        "Alfajor cordobés relleno con dulce de leche, membrillo y frutilla cubiertos con un fino glaseado.",
      detailLine2: "Presentación: caja por 12 y por 6 unidades.",
    },
    alfajoresMaicena: {
      id: "s4",
      titleLine1: "ALFAJORES MAICENA",
      titleLine2: "",
      picture: alfajoresMaicenaPic,
      color: "#bbddd4",
      detailLine1:
        "Alfajor de Maicena relleno con dulce de leche rebozados con coco rallado.",
      detailLine2: "Presentación: caja por 12 unidades",
    },
  });
  useEffect(() => {});
  /*
  useEffect(() => {
    const s1 = document.getElementById('s1');
    const s2 = document.getElementById('s2');
    const s3 = document.getElementById('s3');
    const s4 = document.getElementById('s4');
    window.addEventListener('scroll', () => {
      // s1
      if (window.pageYOffset > (s1.offsetTop + 200) && window.pageYOffset < (s1.offsetTop + 300)) {
        window.scrollTo(0, s2.offsetTop);
      }
      if (window.pageYOffset < (s1.offsetHeight-300) && window.pageYOffset > (s1.offsetHeight - 350)) {
        window.scrollTo(0, s1.offsetTop);
      }
      // s2
      if (window.pageYOffset > (s2.offsetTop + 200) && window.pageYOffset < (s2.offsetTop + 300)) {
        window.scrollTo(0, s3.offsetTop);
      }
      if (window.pageYOffset < (s2.offsetTop + s2.offsetHeight - 300) && window.pageYOffset > (s2.offsetTop + s2.offsetHeight - 350)) {
        window.scrollTo(0, s2.offsetTop);
      }
      // s3
      if (window.pageYOffset > (s3.offsetTop + 200) && window.pageYOffset < (s3.offsetTop + 300)) {
        window.scrollTo(0, s4.offsetTop);
      }
      
      //if (window.pageYOffset < (s3.offsetHeight - 100) && window.pageYOffset > (s3.offsetHeight - 300)) {
        //window.scrollTo(0, s3.offsetTop);
      //}

      //if (window.pageYOffset > (s4.offsetTop + 100) && window.pageYOffset < (s3.offsetTop + 300)) {
        //window.scrollTo(0, s4.offsetTop);
      //}
    });
  });
  */

  return (
    <div className="App">
      <Header />
      <Element name="products">
        <Product productInfo={products.alfajoresNegros} first={true} />
        <Product productInfo={products.alfajoresCordobesesDulceDeLeche} />
        <Product productInfo={products.alfajoresCordobesesMixtos} />
        <Product productInfo={products.alfajoresMaicena} />
      </Element>
      <Element name="about-us">
        <AboutUs />
      </Element>
      <Footer />
      <button
        className="btn-scroll-to-top"
        onClick={() => scroll.scrollToTop()}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
    </div>
  );
}
