import React from 'react';
import './AboutUs.scss';
import aboutUs from '../../about-us.png';


export default function AboutUs() {
  return (
    <section id="s4" className="about-us">
      <img src={aboutUs} alt="logo" />
      <p>
        Somos una fábrica de alfajores artesanales. Nos diferenciamos en el mercado, elaborando productos de calidad con materia prima de excelencia. Nuestros sabores se distinguen en la masa, elaborada cuidadosamente para producir tapas livianas, aireadas y frescas así como también en los exquisitos dulces de excelente calidad. Pretendemos ser la marca riocuartense de alfajores que deleite al pueblo argentino con cada uno de los alimentos que elaboremos.
      </p>
    </section>
  );
}
