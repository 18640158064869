import React from "react";
import "./Product.scss";
import ProductTitle from "../../product-title.png";

export default function Product(props) {
  let wrapperStyle = { backgroundColor: props.productInfo.color };
  if (props.first) {
    wrapperStyle.paddingTop = "215px";
  }
  const productStyle = { backgroundImage: `url(${props.productInfo.picture}` };
  return (
    <section
      id={props.productInfo.id}
      className="product-wrapper"
      style={wrapperStyle}
    >
      <div className="product">
        <div class="product-header" style={productStyle}>
          <img src={ProductTitle} alt="Tradicion Alimentos" />
          <h1>{props.productInfo.titleLine1}</h1>
          <h1>{props.productInfo.titleLine2}</h1>
        </div>

        <div className="product-detail">
          <p>{props.productInfo.detailLine1}</p>
          <p className="subdetail">{props.productInfo.detailLine2}</p>
        </div>
      </div>
    </section>
  );
}
