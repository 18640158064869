import React, { useState, useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import "./Header.scss";
import Contact from "../Contact/Contact";
import logoTradicion from "../../logo-tradicion.png";

export default function Header() {
  const [collapse, setCollapse] = useState(false);
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY === 0) {
        setCollapse(false);
      }
      if (window.scrollY > 50 && window.screenY < 200) {
        setCollapse(true);
      }
    });
  });

  return (
    <header className="header-wrapper">
      <div className={`header ${collapse ? "collapse" : ""}`}>
        <img
          className="header-logo"
          src={logoTradicion}
          alt="Tradicion Alimentos"
          onClick={() => scroll.scrollToTop()}
        />
        <nav className="nav">
          <div className="nav-item-wrapper">
            <Link
              className="nav-btn"
              activeClass="active"
              to="products"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              Productos
            </Link>
          </div>
          <div className="nav-item-wrapper">
            <Link
              className="nav-btn"
              activeClass="active"
              to="about-us"
              spy={true}
              smooth={true}
              offset={-60}
              duration={500}
            >
              La Fabrica
            </Link>
          </div>
          <div className="nav-item-wrapper">
            <Contact show={showContact} setShowContact={setShowContact} />
            <button className="nav-btn" onClick={() => setShowContact(true)}>
              Contacto
            </button>
            {/*<a href="#contacto">Contacto</a> */}
          </div>
        </nav>
      </div>
    </header>
  );
}
