import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.scss";
import logoTradicionOnlyLetters from "../../logo-tradicion-solo-letras.png";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <footer className="footer">
      <img src={logoTradicionOnlyLetters} alt="Tradicion Alimentos" />
      <div className="contact-info">
        <div className="contact-info-item">
          <a href="https://goo.gl/maps/uKNatZLW5LR5EBPo7">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              className="contact-info-item-icon"
            />
            <p>Presidente Perón Centro 360 - Río Cuarto (Córdoba)</p>
          </a>
        </div>
        <div className="contact-info-item">
          <a href="tel:+543585757678">
            <FontAwesomeIcon
              icon={faPhone}
              className="contact-info-item-icon"
            />
            <p>+54 358 575-7678</p>
          </a>
        </div>
        <div className="contact-info-item">
          <a href="mailto:info@tradicionalimentos.com">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="contact-info-item-icon"
            />
            <p>info@tradicionalimentos.com</p>
          </a>
        </div>
      </div>
      <div className="social-media">
        <a href="https://www.facebook.com/tradicionalimentos/">
          <FontAwesomeIcon className="social-media-icon" icon={faFacebookF} />
        </a>
        <a href="https://www.instagram.com/tradicion.ok/">
          <FontAwesomeIcon className="social-media-icon" icon={faInstagram} />
        </a>
      </div>
    </footer>
  );
}
